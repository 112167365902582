const en = {
    commerce: 'Commerce',
    charges: 'Charges',
    transactions: 'Transactions',
    user: 'User',
    myCommerce: 'My commerce',
    backup: 'Transaction backup',
    cancelTrans: 'Cancel transaction'
};

const es = {
    commerce: 'Comercio',
    charges: 'Cobros',
    transactions: 'Transacciones',
    user: 'Usuario',
    myCommerce: 'Mi commercio',
    backup: 'Respaldo de transacciones',
    cancelTrans: 'Cancelar transacción'
};

module.exports = {
    en,
    es
};
