import React from "react";
import PostDoc from '../../../components/DocumentDisplay/postDoc';
import SidebarMpos from "../../../components/SidebarMpos/sidebarMpos";

const mpos = () => {
    return (
        <div>
            <SidebarMpos />
            <PostDoc file='mpos/app/mpos.md'/>
        </div>
    );
}

export default mpos;
