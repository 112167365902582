const en = {
    email: 'Your email',
    comments: 'Your comment...',
    hear: 'We want to hear from you',
    paragRegular: 'Our ',
    paragBold: 'advisors ',
    paragRegular2: 'are ready to work hand in hand with you',
    send: 'SEND',
    followUs: 'FOLLOW US',
    conditions: '© 2021 Espiral Technologies de México SA de CV ',
    link: 'Terms and Conditions & Privacy Policy.'
};

const es = {
    email: 'Tu correo',
    comments: 'Deja tu comentario...',
    hear: 'Contactános',
    paragRegular: 'Nuestros ',
    paragBold: 'asesores ',
    paragRegular2: 'están listos para trabajar de la mano contigo',
    send: 'ENVIAR',
    followUs: 'Síguenos',
    conditions: '© 2021 Espiral Technologies de México SA de CV ',
    link: 'Terminos y condiciones & Politicas de Privacidad.'
};

module.exports = {
    en,
    es
};
