import appbar from '../tanslations/components/appbar';
import landing from '../tanslations/components/landing';
import sidebar from '../tanslations/components/sidebar';
import sidebarmpos from '../tanslations/components/sidebarMpos';
import download from '../tanslations/components/download';
import sectionContact from '../tanslations/components/sectionContact';

export default {
    en: {
        appbar: appbar.en,
        landing: landing.en,
        sidebar: sidebar.en,
        sidebarmpos: sidebarmpos.en,
        download: download.en,
        sectionContact: sectionContact.en
    },
    es: {
        appbar: appbar.es,
        landing: landing.es,
        sidebar: sidebar.es,
        sidebarmpos: sidebarmpos.es,
        download: download.es,
        sectionContact: sectionContact.es
    }
  }
  