import './App.scss';
import { useTranslation } from 'react-i18next';
import GrandBtn from './components/GrandBtn/grandBtn';
import SectionContact from './components/sectionContact/SectionContact';
import SectionDownload from './components/sectionDownload/SectionDownload';

function App(props) {
  const [t, i18n] = useTranslation();
  const toMpos = "/mpos/app/mpos";
  const toEcommerce = '/ecommerce/api/webServices3DSecure';
  const toHotlink = '/ecommerce/dash/hotlink';
  const toReferencedPayment = '/ecommerce/api/referencedPayments'

  return (
    <div className="App">
      <div className="overlay-container">
        <div className="section-header">
          <div className='text'>
            <h1>{t('landing.heading1')}</h1>
            <h1>{t('landing.heading2')}</h1>
          </div>
        </div>
        <div className="section-links">
          <GrandBtn
            shadowColor = 'mpos'
            linkTo = {toMpos}
            btnIcon = '/img/PNG/Recurso.png'
            iconSize = '30px'
            btnTxt = {t('landing.mposBtn')}
          />
          <GrandBtn
            shadowColor = 'ecommerce'
            linkTo = {toEcommerce}
            btnIcon = '/img/PNG/008_Ecommerce-8.png'
            iconSize = '50px'
            btnTxt = {t('landing.ecomBtn')}
          />
          <GrandBtn
            shadowColor = 'ecommerce'
            linkTo = {toHotlink}
            btnIcon = '/img/PNG/009_linkcobro-8.png'
            iconSize = '50px'
            btnTxt = {t('landing.hotBtn')}
          />
          <GrandBtn
            shadowColor = 'ecommerce'
            linkTo = {toReferencedPayment}
            btnIcon = '/img/PNG/010_referencias-8.png'
            iconSize = '30px'
            btnTxt = {t('landing.refBtn')}
          />
          {/* <GrandBtn
            shadowColor = 'ecommerce'
            linkTo = {toMpos}
            btnIcon = '/img/PNG/011_transferencias-8.png'
            iconSize = '20px'
            btnTxt = {t('landing.transferBtn')}
          /> */}
        </div>
        <img className='img-overlay' src="/img/PNG/006_Backright-8.png" alt='espiralimage'/>
      </div>
      <SectionDownload />
        <SectionContact>
      </SectionContact>
    </div>
  );
}

export default App;
