const en = {
    heading1: 'Our service',
    heading2: 'API Docs',
    mposBtn: 'Mpos',
    ecomBtn: 'Ecommerce',
    hotBtn: 'Hotlink',
    refBtn: 'Referenced payment',
    transferBtn: 'Payment transfer',
};

const es = {
    heading1: 'Nuestro servicio',
    heading2: 'API Docs',
    mposBtn: 'Mpos Terminales ',
    ecomBtn: 'Ecommerce',
    hotBtn: 'Link de cobro',
    refBtn: 'Pagos referenciados',
    transferBtn: 'Transferencia de pagos',
};

module.exports = {
    en,
    es
}
