import React from "react";
import PostDoc from '../../../components/DocumentDisplay/postDoc';
import SideBar from "../../../components/SideBarEcomApi/sideBar";

const Api = () => {
    return (
        <div>
            <SideBar />
            <PostDoc file='ecommerce/dash/api-keys.md'/>
        </div>
    );
}

export default Api;
