import React from "react";
import PostDoc from "../../../components/DocumentDisplay/postDoc";
import SideBar from "../../../components/SideBarEcomApi/sideBar";

const WordpressPlug = () => {
    return (
        <div className="docContainer" >
            <SideBar />
            <PostDoc file="ecommerce/api/wordpressEcom.md" />
        </div>
    );
}

export default WordpressPlug;