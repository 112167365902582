import React from "react";
import { useEffect } from "react";
import PostDoc from '../../../components/DocumentDisplay/postDoc';
import SideBar from "../../../components/SideBarEcomApi/sideBar";

let src = 'ecommerce/api/automatedPayment.md';

let AutoPayment = () => {

    useEffect(() => {
        console.log('use efect');
        if(localStorage.getItem('lang') === "es") {
            src = 'ecommerce/api/automatedPayment.md';
            console.log('ahhhh ' + src)
        }
        else {
            src = 'en/ecommerce/api/automatedPayment.md';
            console.log('engle ' + src);
        }
    });

    return (
        <div className="docContainer">
            <SideBar />
            <PostDoc file = {src} />
        </div>
    );
}

export default AutoPayment;