import React from "react";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import './sidebarMpos.scss'

const SidebarMpos = () => {
    const [t, i18n] = useTranslation();

    const handleOpen = () => {
        document.getElementById("respEco").style.left = "0";
        document.getElementById("arrowOpen").style.display = "none";
    }

    const handleClose = () => {
        document.getElementById("respEco").style.left = "-15rem";
        document.getElementById("arrowOpen").style.display = "block";
    }

    return(
        <div>
            <div className="sideBarEco" id="respEco">
                <ul>
                    <li className="title">App</li>
                    <li className="item">
                        <Link to="/mpos/app/mpos">Mpos</Link>
                    </li>
                    <li className="item">
                        <Link to="/mpos/app/user">{t('sidebarmpos.user')}</Link>
                    </li>
                    <li className="item">
                        <Link to="/mpos/app/merchant">{t('sidebarmpos.commerce')}</Link>
                    </li>
                    <li className="item">
                        <Link to="/mpos/app/payments">{t('sidebarmpos.charges')}</Link>
                    </li>
                    <li className="item">
                        <Link to="/mpos/app/hotlink">Hotlink</Link>
                    </li>
                    <li className="item">
                        <Link to="/mpos/app/transaction">{t('sidebarmpos.transactions')}</Link>
                    </li>
                    <li className="title">Dashboard</li>
                    <li className="item">
                        <Link to="/mpos/dashboard/myCommerce">{t('sidebarmpos.myCommerce')}</Link>
                    </li>
                    <li className="item">
                        <Link to="/mpos/dashboard/transactions">{t('sidebarmpos.transactions')}</Link>
                    </li>
                    <li className="title">Api</li>
                    <li className="item">
                        <Link to="/mpos/api/voucher">Voucher</Link>
                    </li>
                    <li className="item">
                        <Link to="/mpos/api/respaldo">{t('sidebarmpos.backup')}</Link>
                    </li>
                    <li className="item">
                        <Link to="/mpos/api/cancelTrans">{t('sidebarmpos.cancelTrans')}</Link>
                    </li>
                </ul>
                <div className="arrow" id="arrowClose">
                    <ArrowBackIosIcon onClick={handleClose} />
                </div>
            </div>
            <div className="arrow" id="arrowOpen">
                <ArrowForwardIosIcon
                    
                    onClick={handleOpen}
                />
            </div>
        </div>
    );
}

export default SidebarMpos;