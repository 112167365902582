import React, { useEffect, useState } from "react";
import Markdown from "markdown-to-jsx";
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import { darcula as CodeStyle } from 'react-syntax-highlighter/dist/esm/styles/prism';
import "./postDoc.scss";

const CodeBlock = ({className, children}) => {
  let lang = 'text'; 
  if (className && className.startsWith('lang-')) {
    lang = className.replace('lang-', '');
  }
  return (
    <SyntaxHighlighter language={lang} style={CodeStyle}>
      {children}
    </SyntaxHighlighter>
  );
}
  
const PreBlock = ({children, ...rest}) => {
  if ('type' in children && children ['type'] === 'code') {
    return CodeBlock(children['props']);
  }
  return <pre {...rest}>{children}</pre>;
};

const PostDoc = (props) => {
  let file_name = props.file;
  const [post, setPost] = useState('');
  // console.log(props);
  
    useEffect(() => {
        import(`../../docs/${file_name}`)
            .then(res => {
                fetch(res.default)
                    .then(res => res.text())
                    .then(res => setPost(res))
                    .catch(err => err);
            })
            .catch(err => console.log(err));
    });

    return (
        <div className="doc-container">
            <Markdown
                options={{
                    overrides: {
                      pre: PreBlock,
                    }
                  }}
            >
                { post }
            </Markdown>
        </div>
    );
}

export default PostDoc;
