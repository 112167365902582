import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import CssBaseline from '@mui/material/CssBaseline';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Slide from '@mui/material/Slide';

import './appbar.scss';

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const ResponsiveAppBar = (props) => {
  const [t, i18n] = useTranslation();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElMen, setAnchorElMen] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenMenMenu = (event) => {
    setAnchorElMen(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseMenMenu = () => {
    setAnchorElMen(null);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('lang', lng);
    this.props.changeLanguage(lng);
    setAnchorElUser(null);
  }

  return (
    <div className='appBarComponent'>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar>
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ mr: 0, display: { xs: 'none', md: 'flex' } }}
              >
                <Link to="/" className="navbar__item navbar__link logo"> 
                    <img className="logo" src="/img/PNG/001_Logo-8.png" alt="espirallogo" />
                </Link>
              </Typography>

              <Box 
                sx={{
                  // flexGrow: 2,
                  display: {
                    xs: 'flex',
                    md: 'none'
                  }
                }}
              >
                <IconButton
                  size="large"
                  aria-label="espiralapp"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <img className="logo" src="/img/PNG/001_Logo-8.png" alt="espirallogo" />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >  
                <MenuItem onClick={handleCloseNavMenu}>
                    <a href="https://mpos.espiralapp.com/" className="navbar__items navbar__item navbar__link">
                        <p>{t('appbar.mpos')}</p>
                        <img className="icon" src="/img/PNG/002_MPOS-8.png" alt="espirallogo" />
                    </a>
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                    <a href="https://ecommerce.espiralapp.com/" className="navbar__items navbar__item navbar__link">
                        <p>{t('appbar.ecommerce')}</p>
                        <img className="iconkart" src="/img/PNG/003_Ecommerce-8.png" alt="espirallogo" />
                    </a>
                </MenuItem>
                </Menu>
              </Box>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
              >
                <a href="/" className="navbar__items navbar__item navbar__link">
                    <img className="iconkart" src="/img/PNG/004_Desarrolladores-8.png" alt="espirallogo" />
                </a>
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                  <Button
                    onClick={handleCloseNavMenu}
                    sx={{ my: 1, color: 'white', display: 'block' }}
                  >
                    <a href="https://mpos.espiralapp.com/" className="navbar__items navbar__item navbar__link" target="_blank" rel="noreferrer">
                        <p>{t('appbar.mpos')}</p>
                        <img className="icon" src="/img/PNG/002_MPOS-8.png" alt="espirallogo" />
                    </a>
                  </Button>
                  <Button
                    onClick={handleCloseNavMenu}
                    sx={{ my: 1, color: 'white', display: 'block' }}
                  >
                    <a href="https://ecommerce.espiralapp.com/" className="navbar__items navbar__item navbar__link" target="_blank" rel="noreferrer">
                        <p>{t('appbar.ecommerce')}</p>
                        <img className="iconkart" src="/img/PNG/003_Ecommerce-8.png" alt="espirallogo" />
                    </a>
                  </Button>
                  <Button
                    onClick={handleCloseNavMenu}
                    sx={{ my: 1, color: '#0C88E0', display: 'block' }}
                  >
                    <Link to="/" className="navbar__items navbar__item navbar__link">
                        <p>{t('appbar.devs')}</p>
                        <img className="iconkart" src="/img/PNG/004_Desarrolladores-8.png" alt="espirallogo" />
                    </Link>
                  </Button>
              </Box>

              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="espiralapp"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenMenMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElMen}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElMen)}
                  onClose={handleCloseMenMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >  
                {/* <MenuItem onClick={handleCloseMenMenu}>
                  <Button
                    onClick={handleCloseMenMenu}
                    sx={{ my: 1, color: 'white', display: 'block' }}
                  >
                    <a href="/" className="navbar__items navbar__item navbar__link espiral-blue">
                        <p>{t('appbar.start')}</p>
                    </a>
                  </Button>
                </MenuItem> */}
                <MenuItem onClick={handleCloseMenMenu}>
                  <a href="https://dash.espiralapp.com/es/register" className='center' target='_blank' rel="noreferrer">
                    <Button
                      variant="contained"
                      size="small"
                      onClick={handleCloseMenMenu}
                      sx={{ my: 1, color: 'white', display: 'block' }}
                    >
                        {t('appbar.registerBtn')} 
                    </Button>
                  </a>
                </MenuItem>
                </Menu>
              </Box>
              {/* <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Idioma">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} className="navbar__items navbar__item navbar__link">
                    <p className='small-doc-blue'>{t('appbar.lang')}</p>
                    <img className="iconFlag" src="/img/PNG/005_Idioma-8.png" alt="espirallogo" />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                <MenuItem onClick={() => changeLanguage('es')}>
                    <Typography textAlign="center">
                    {t('appbar.spa')}
                    </Typography>
                </MenuItem>
                <MenuItem onClick={() => changeLanguage('en')}>
                    <Typography textAlign="center">
                    {t('appbar.eng')}
                    </Typography>
                </MenuItem>
                </Menu>
              </Box> */}
              <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                  {/* <Button
                    onClick={handleCloseUserMenu}
                    sx={{ my: 1, color: 'white', display: 'block' }}
                  >
                    <a href="/" className="navbar__items navbar__item navbar__link small-doc-blue">
                        <p>{t('appbar.start')}</p>
                    </a>
                  </Button> */}
                  <a href="https://dash.espiralapp.com/es/register" className='center' target='_blank' rel="noreferrer">
                    <Button
                      variant="contained"
                      size="small"
                      onClick={handleCloseUserMenu}
                      sx={{ my: 1, color: 'white', display: 'block' }}
                      className="small-font"
                    >
                        {t('appbar.registerBtn')} 
                    </Button>
                  </a>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </div>
  );
};
export default ResponsiveAppBar;
