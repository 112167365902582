import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';

import './SectionDownload.scss';

const SectionDownload = () => {
  const [t, i18n] = useTranslation();
  return (
    <div className="section-get-espiral" id="SectionGetEspiral">
      <div className="espiral-content">
        <h1>
          <span>
            {t('download.get')}
          </span>
          <span> 
            {t('download.espiral')}
          </span>
        </h1>
        <div className="header__button text-center">
          <a href="https://dash.espiralapp.com/es/login" target="_blank" rel="noopener noreferrer">
            <Button className="btn-send" variant="contained">
              {t('download.createAcc')}
            </Button>
          </a>
        </div>
        <div className='getText'>
          <p>
            {t('download.paragraphReg')}
            <span>
              {t('download.paragraphBold')}
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default SectionDownload;
