import React from 'react';
import {
  Link
} from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import './Footer.scss';

const Footer = () => {
  const [t, i18n] = useTranslation();
  return (
    <footer id="SectionFooter">
      <Box maxWidth="xl" className="footer-vert-gap" id="txt-white">
        <Grid container spacing={1} className="center">
          <Grid item sm={12} xs={12}>
            <div className="container-footer-info">
              <div>
                <img src="/img/PNG/016_logoespiralapp-8.png" className="image-logo" alt="logoEspiralapp" />
              </div>
              <div className="footer-mail">
                <p className="link">
                  <a href="tel:+3317216417">
                    33172 16417
                  </a>
                </p>
              </div>
              <div
                className="footer-follow"
                >
                {t('sectionContact.followUs')}
              </div>
              <div className='footer-icons'>
                <a target="_blank" rel="noreferrer noopener" href="https://www.facebook.com/MyEspiralApp">
                  <img src="/img/SVG/019_logo_facebook.svg" className="footer-social" alt="facebook" />
                </a>
                <a target="_blank" rel="noreferrer noopener" href="https://www.instagram.com/myespiralapp/">
                  <img src="/img/SVG/020_logo_instagram.svg" className="footer-social" alt="instagram" />
                </a>
                {/* <a target="_blank" rel="noreferrer noopener" href="https://t.me/belugapay">
                  <img src="/img/021_logo_telegram.svg" className="footer-social" alt="telegram" />
                </a> */}
                <a target="_blank" rel="noreferrer noopener" href="https://www.linkedin.com/company/myespiralapp">
                  <img src="/img/SVG/022_logo_linkedin.svg" className="footer-social" alt="linkedin" />
                </a>
                <a target="_blank" rel="noreferrer noopener" href="https://twitter.com/MyEspiralApp">
                  <img src="/img/SVG/023_logo_twitter.svg" className="footer-social" alt="twitter" />
                </a>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <div className="footer text-center light">
          {t('sectionContact.conditions')}
          <Link to="/terms-and-conditions">{t('sectionContact.link')}</Link>
        </div>
      </Box>
    </footer>
  )
}

export default Footer;
