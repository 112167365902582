const en = {
    get: 'Get ',
    espiral: 'Espiralapp ',
    createAcc: 'CREATE AN ACCOUNT',
    paragraphReg: 'Know our products, download our app for collections and ',
    paragraphBold: 'accept payments today',
};

const es = {
    get: 'Obten ',
    espiral: 'Espiralapp',
    createAcc: 'CREA UNA CUENTA',
    paragraphReg: 'Conoce nuestros productos, descarga nuestra app para cobros y ',
    paragraphBold: 'acepta pagos hoy',
};

module.exports = {
    en,
    es
};
