import React, { Component } from "react";
import { Link } from 'react-router-dom';
import './grandBtn.scss';
import i18n from '../../i18n';

class GrandBtn extends Component {
    changeLanguage(lng) {
        i18n.changeLanguage(lng);
        localStorage.setItem('lang', lng);
        this.props.changeLanguage(lng);
    }

    render() {
        return(
             <Link to={this.props.linkTo} className={`GrandBtn GrandBtn-${this.props.shadowColor} btn btn-${this.props.shadowColor}`}> 
                <div className="btn-content">
                    <img width={this.props.iconSize} src={this.props.btnIcon} alt="icon" />
                    <p className="btn-content__text">{this.props.btnTxt}</p>   
                </div>  
            </Link>
        )
    }
}
export default GrandBtn;