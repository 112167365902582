const en = {
    scheduledCharge: 'Scheduled charge',
    endpoints: 'Endpoints',
    genRef: 'Generate a payment order',
    services: 'Services',
    wordpress: 'Wordpress',
    dashboard: 'Dashboard',
    intro: 'Introduction',
    hotlink: 'Hotlink',
    apikeys: 'Api keys',
    chargeback: 'Chargeback',
    schdCharge: 'Scheduled charge',
    transLog: 'Transaction log',
    transView: 'Transaction view',
    get: 'Get ',
    espiral: 'Espiralapp ',
    createAcc: 'CREATE AN ACCOUNT',
    paragraphReg: 'Know our products, <br/> download our app for collections <br/> and ',
    paragraphBold: 'accept payments today',
};

const es = {
    scheduledCharge: 'Cargo programado',
    endpoints: 'Endpoints',
    genRef: 'Generar orden de pago',
    services: 'Servicios',
    wordpress: 'Wordpress',
    dashboard: 'Dashboard',
    intro: 'Introduccion',
    hotlink: 'Hotlink',
    apikeys: 'Api keys',
    chargeback: 'Contra cargo',
    schdCharge: 'Cobro express programado',
    transLog: 'Log de transacciones',
    transView: 'Vista de transacciones',
    get: 'Obten ',
    espiral: 'Espiralapp',
    createAcc: 'CREA UNA CUENTA',
    paragraphReg: 'Conoce nuestros productos, <br/> descarga nuestra app para cobros <br/> y ',
    paragraphBold: 'acepta pagos hoy',
};

module.exports = {
    en,
    es
};
