import React from "react";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import './sideBar.scss'

const SideBar = () => {
    const [t, i18n] = useTranslation();

    const handleOpen = () => {
        document.getElementById("respEco").style.left = "0";
        document.getElementById("arrowOpen").style.display = "none";
    }

    const handleClose = () => {
        document.getElementById("respEco").style.left = "-15rem";
        document.getElementById("arrowOpen").style.display = "block";
    }


    return(
        <div>
            <div className="sideBarEco" id="respEco">
                <ul>
                    <li className="title">Api</li>
                    {/* <li className="item">
                        <Link to="/ecommerce/api/automatedPayment">{t('sidebar.scheduledCharge')}</Link>
                    </li>
                    <li className="item">
                        <Link to="/ecommerce/api/endpoints">{t('sidebar.endpoints')}</Link>
                    </li> */}
                    <li className="item">
                        <Link to="/ecommerce/api/referencedPayments">{t('sidebar.genRef')}</Link>
                    </li>
                    {/* <li className="item">
                        <Link to="/ecommerce/api/webServices3DSecure">{t('sidebar.services')}</Link>
                    </li>
                    <li className="item">
                        <Link to="/ecommerce/api/wordpressEcom">{t('sidebar.wordpress')}</Link>
                    </li> */}

                    <li className="title">{t('sidebar.dashboard')}</li>
                    <li className="item">
                        <Link to="/ecommerce/dash/introduction">{t('sidebar.intro')}</Link>
                    </li>
                    <li className="item">
                        <Link to="/ecommerce/dash/hotlink">{t('sidebar.hotlink')}</Link>
                    </li>
                    <li className="item">
                        <Link to="/ecommerce/dash/apiKeys">{t('sidebar.apikeys')}</Link>
                    </li>
                    <li className="item">
                        <Link to="/ecommerce/dash/chargebacks">{t('sidebar.chargeback')}</Link>
                    </li>
                    <li className="item">
                        <Link to="/ecommerce/dash/scheduledCharge">{t('sidebar.schdCharge')}</Link>
                    </li>
                    <li className="item">
                        <Link to="/ecommerce/dash/transactionLog">{t('sidebar.transLog')}</Link>
                    </li>
                    <li className="item">
                        <Link to="/ecommerce/dash/transactionView">{t('sidebar.transView')}</Link>
                    </li>
                </ul>
                <div className="arrow">
                    <ArrowBackIosIcon onClick={handleClose} />
                </div>
            </div>
            <div className="arrow" id="arrowOpen">
                <ArrowForwardIosIcon
                    id="arrowOpen"
                    onClick={handleOpen}
                />
            </div>
        </div>
    );
}

export default SideBar;