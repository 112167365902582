const en = {
    mpos: 'MPOS Devices',
    ecommerce: 'Ecommerce',
    devs: 'Developers',
    lang: 'En',
    spa: 'Spanish',
    eng: 'English',
    start: 'Start',
    registerBtn: 'Register'
}

const es = {
    mpos: 'Terminales MPOS',
    ecommerce: 'Ecommerce',
    devs: 'Desarrolladores',
    lang: 'Es',
    spa: 'Español',
    eng: 'Ingles',
    start: 'Inicio',
    registerBtn: 'Registrate'
}

module.exports = {
    en,
    es
}
