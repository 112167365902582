import React from "react";
import PostDoc from '../../../components/DocumentDisplay/postDoc';
import SideBar from "../../../components/SideBarEcomApi/sideBar";

const endpoints = () => {
    return (
        <div className="docContainer" >
            <SideBar />
            <PostDoc file='ecommerce/api/endpoints.md'/>
        </div>
    );
}

export default endpoints;