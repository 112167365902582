import React from "react";
import PostDoc from '../../../components/DocumentDisplay/postDoc';
import SideBar from "../../../components/SideBarEcomApi/sideBar";

const referencedPayment = () => {
    return (
        <div className="docContainer" >
            <SideBar />
            <PostDoc file='ecommerce/api/referencedPayments.md'/>
        </div>
    );
}

export default referencedPayment;
