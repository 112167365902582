import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ResponsiveAppBar from './components/AppBar/appbar';
import './i18n';

import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import AutomatedPayment from './routes/ecommerce/api/automatedPayment';
import Endpoints from './routes/ecommerce/api/endpoints';
import ReferencedPayments from './routes/ecommerce/api/referencedPayments';
import WebServices from './routes/ecommerce/api/webServices3DSecure';
import WordpressPlug from './routes/ecommerce/api/wordpressEcom';
import Api from './routes/ecommerce/dash/apiKeys';
import Chargeback from './routes/ecommerce/dash/chargebacks';
import Hotlink from './routes/ecommerce/dash/hotlink';
import Introduction from './routes/ecommerce/dash/introduction';
import ScheduledCharge from './routes/ecommerce/dash/shceduledCharge';
import TransactionLog from './routes/ecommerce/dash/transactionLog';
import TransactionView from './routes/ecommerce/dash/transactionView';

import HotlinkMpos from './routes/mpos/app/hotlink';
import Merchant from './routes/mpos/app/merchant';
import Mpos from './routes/mpos/app/mpos';
import Payments from './routes/mpos/app/payments';
import Transaction from './routes/mpos/app/transaction';
import User from './routes/mpos/app/user';
import MyCommerce from './routes/mpos/dashboard/myCommerce';
import Transactions from './routes/mpos/dashboard/transactions';
import Backup from './routes/mpos/api/respaldo';
import Cancel from './routes/mpos/api/cancelTrans';
import Voucher from './routes/mpos/api/voucher';

let changeLanguage = (lang) => {
  console.log('sexooo ' + lang);
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ResponsiveAppBar changeLanguage={changeLanguage} />
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="ecommerce/api/automatedPayment" element={<AutomatedPayment />} />
        <Route path="ecommerce/api/endpoints" element={<Endpoints />} />
        <Route path="ecommerce/api/referencedPayments" element={<ReferencedPayments />} />
        <Route path="ecommerce/api/webServices3DSecure" element={<WebServices />} />
        <Route path="ecommerce/api/wordpressEcom" element={<WordpressPlug />} />
        <Route path="ecommerce/dash/apikeys" element={<Api />} />
        <Route path="ecommerce/dash/chargebacks" element={<Chargeback />} />
        <Route path="ecommerce/dash/hotlink" element={<Hotlink />} />
        <Route path="ecommerce/dash/introduction" element={<Introduction />} />
        <Route path="ecommerce/dash/scheduledCharge" element={<ScheduledCharge />} />
        <Route path="ecommerce/dash/transactionLog" element={<TransactionLog />} />
        <Route path="ecommerce/dash/transactionView" element={<TransactionView />} />
        <Route path="mpos/app/hotlink" element={<HotlinkMpos />} />
        <Route path="mpos/app/merchant" element={<Merchant />} />
        <Route path="mpos/app/mpos" element={<Mpos />} />
        <Route path="mpos/app/payments" element={<Payments />} />
        <Route path="mpos/app/transaction" element={<Transaction />} />
        <Route path="mpos/app/user" element={<User />} />
        <Route path="mpos/dashboard/myCommerce" element={<MyCommerce />} />
        <Route path="mpos/dashboard/transactions" element={<Transactions />} />
        <Route path="mpos/api/respaldo" element={<Backup />} />
        <Route path="mpos/api/cancelTrans" element={<Cancel />} />
        <Route path="mpos/api/voucher" element={<Voucher />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
